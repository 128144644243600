<template>
  <div>
    <app-loading v-if="loading" />
    <v-container v-if="!loading">
      <new-move
        v-if="showNewMoveModal"
        :account-parent="editAccount"
      />
      <edit-move v-if="showEditMoveModal" :editMove="editMove" :current-balance="editAccount.current_balance"/>
      <v-card>
        <v-app-bar
          v-if="editAccount.id"
          flat
          dense
          color="rgba(0, 0, 0, 0)"
        >
          <h4>{{ $vuetify.lang.t('$vuetify.menu.accounting') + ': ' + editAccount.name }}</h4>
          <v-spacer />
          <h4>{{ $vuetify.lang.t('$vuetify.boxes.init') + ': ' + parseFloat(editAccount.init_balance).toFixed(2) }}</h4>
          <v-spacer />
          <h4>{{ $vuetify.lang.t('$vuetify.boxes.current') + ': ' + parseFloat(editAccount.current_balance).toFixed(2) }}</h4>
        </v-app-bar>
        <v-card-text>
          <v-row>
            <v-col
              class="py-0"
              cols="12"
            >
              <app-data-table
                :title="
                  $vuetify.lang.t('$vuetify.titles.list', [
                    $vuetify.lang.t('$vuetify.menu.account_moves')
                  ])
                "
                :viewBackButton="true"
                :routerGoBack="'accounting_account'"
                csv-filename="Categories"
                :headers="getTableColumns"
                :items="editAccount.move ? editAccount.move : []"
                :manager="'accounting_account'"
                :sort-by="['firstName']"
                :sort-desc="[false, true]"
                multi-sort
                @create-row="toogleNewMovesModal(true)"
                @edit-row="editMoveHandler($event)"
                @delete-row="deleteMoveHandler($event)"
              >
              </app-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import NewMove from './NewMove'
import EditMove from './EditMove'

export default {
  name: 'ListAccountMove',
  components: { NewMove, EditMove },
  data () {
    return {
      loading: false,
      editMove: null
    }
  },
  computed: {
    ...mapState('accountMove', [
      'showNewMoveModal',
      'showEditMoveModal',
      'isTableLoading'
    ]),
    ...mapState('account', ['editAccount']),
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.date'),
          value: 'date',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.accounting_category.assets'),
          value: 'credit',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.accounting_category.debit'),
          value: 'debit'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  async created () {
    this.loading = true
    await this.getEditAccount(this.$route.params.account)
    this.loading = false
  },
  methods: {
    ...mapActions('accountMove', ['getMovesByAccount', 'toogleNewMovesModal', 'openEditMovesModal', 'deleteMove']),
    ...mapActions('account', ['getEditAccount']),
    editMoveHandler ($id) {
      this.editMove = this.editAccount.move.filter(move => move.id === $id)[0]
      this.openEditMovesModal($id)
    },
    deleteMoveHandler ($id) {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.titles.delete', [
            this.$vuetify.lang.t('$vuetify.menu.account_move')
          ]),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.warning_delete'
          ),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.delete'
          ),
          confirmButtonColor: 'red'
        })
        .then(result => {
          if (result.value) {
            this.deleteMove($id).then(() => {
              this.getEditAccount(this.$route.params.account).then((ac) => {
                this.editAccount = ac
              })
            })
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
